import React from "react"

/**
 * Materials
 */
import { AskAccepted } from "../../../components/contact/casualvisit/accepted"

const breadcrumbsData: { text: string; path: string }[] = [
  { text: `CONTACT`, path: `contact` },
  { text: `カジュアル面談`, path: `contact/casualvisit` },
]

/**
 * Component
 */
export default function Home() {
  return <AskAccepted breadcrumbsData={breadcrumbsData} />
}
